body {
  margin: 0;
  font: 500 90% / 20px Ubuntu, sans-serif;
  color: rgb(100, 88, 92);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark-bg {
  background-color: #013d73;
}

.modal-90w {
  width: 90%;
  max-width: none!important;
}

.selection-cell input {
  visibility: hidden;
}
.selection-cell:before {
  content: "✐";
  visibility: visible;
  color: #fff;
  background-color: #0267BF;
  border-color: #0267BF;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0 0.5rem;
  font-size: 1.5rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.selection-cell:hover:before {
  color: #fff;
  background-color: #0267BF;
  border-color: #0267BF;
}

.react-bootstrap-table th[data-row-selection] {
  width: 57px !important;
}

.react-bootstrap-table th[data-row-selection]::before {
  content: 'Edit';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  background-color: var(--light);
  border-radius: 4px;
  border: 2px solid var(--secondary);
  padding:0.8rem;
  white-space: pre-wrap;
}
.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  background-color: var(--light);
  border-radius: 4px;
  border: 2px solid var(--secondary);
  padding:0.8rem;
  white-space: pre-wrap;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
.card-columns-5 {
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
}

.card-columns-5 .card{
  display: block;
  margin-bottom: 1rem;
}

.row-responsive {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.column-responsive {
  display: inline-block;
  min-width:1180px;
  overflow-x: auto;
  -webkit-box-pack: unset;
  -webkit-justify-content: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
  -webkit-align-items: unset;
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
}
.card-columns-5 .card hr{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.copy-area {
  display: inline;
  margin-left: 0.5rem;
}

.code-box {
  display: inline-block;
  ;
}

/* TOGGLE STYLING */
.toggle {
  margin: 0 0 1.5rem;
  box-sizing: border-box;
  font-size: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
.toggle-grouped {
  display: inline-flex;
  margin-right: 0.5rem;
}
.toggle input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.toggle input + label {
  margin: 0;
  padding: 0.75rem 2rem;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  border: solid 1px #ddd;
  background-color: #fff;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  transition: border-color 0.15s ease-out, color 0.25s ease-out, background-color 0.15s ease-out, box-shadow 0.15s ease-out;
 /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
 /*flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
 */
 /* ----- */
}
.toggle input + label:first-of-type {
  border-radius: 6px 0 0 6px;
  border-right: none;
}
.toggle input + label:last-of-type {
  border-radius: 0 6px 6px 0;
  border-left: none;
}
.toggle input:hover + label {
  border-color: #023E73;
}
.toggle input:checked + label {
  background-color: #023E73;
  color: #fff;
  box-shadow: 0 0 10px rgba(102, 179, 251, .5);
  z-index: 0;
}
.toggle-grouped input:checked[value="false"] + label { background-color: #BF0436; }​
.toggle input:focus + label {
  outline: dotted 1px #ccc;
  outline-offset: 0.45rem;
}

.sidebar-highlight {
  background-color: var(--success) !important;
}
.sidebar-highlight:hover {
  background-color: #23a067 !important;
}

.checkbox-xl input{
  width:30px;
  height:30px;
}
.checkbox-xl .form-check-label::before, 
.checkbox-xl .form-check-label::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}

.checkbox-xl .form-check-label {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
}

/* min hight for contact-us form */
.ql-editor{
  min-height:200px;
}

/*placeholder component*/
.placeholder .btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow{
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.6;
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.3;
  }
}

.placeholder-wave {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.7;
  mask-image: linear-gradient(130deg, black 55%, rgba(0, 0, 0, (1 - 0.3)) 75%, black 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

/* modal close button */
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0.5rem 0.5rem auto;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

/* Accordian Bootstrap */
.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: #e4f2ff;
  --bs-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #0d6efd40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle)
}

.accordion-button {
  align-items: center;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  color: var(--bs-accordion-btn-color);
  display: flex;
  font-size: 1rem;
  overflow-anchor: none;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  position: relative;
  text-align: left;
  transition: var(--bs-accordion-transition);
  width: 100%
}

@media (prefers-reduced-motion:reduce) {
  .accordion-button {
      transition: none
  }
}

.accordion-button:not(.collapsed) {
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width)*-1) 0 var(--bs-accordion-border-color);
  color: var(--bs-accordion-active-color)
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform)
}

.accordion-button:after {
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  content: "";
  flex-shrink: 0;
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  transition: var(--bs-accordion-btn-icon-transition);
  width: var(--bs-accordion-btn-icon-width)
}

@media (prefers-reduced-motion:reduce) {
  .accordion-button:after {
      transition: none
  }
}

.accordion-button:hover {
  z-index: 2
}

.accordion-button:focus {
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
  z-index: 3
}

.accordion-header {
  margin-bottom: 0
}

.accordion-item {
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  color: var(--bs-accordion-color)
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius)
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:not(:first-of-type) {
  border-top: 0
}

.accordion-item:last-of-type {
  border-bottom-left-radius: var(--bs-accordion-border-radius);
  border-bottom-right-radius: var(--bs-accordion-border-radius)
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: var(--bs-accordion-border-radius);
  border-bottom-right-radius: var(--bs-accordion-border-radius)
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)
}

.accordion-flush .accordion-collapse {
  border-width: 0
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0
}

.accordion-flush .accordion-item:first-child {
  border-top: 0
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button,.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0
}

[data-bs-theme=dark] .accordion-button:after {
  --bs-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
}

.form-multi-select {
  border-width: 0;
  min-width: 0;
  width: 100%;
  padding: 0;
}

.form-control {
  height: unset !important;
  min-height: calc(1.5em + 0.75rem + 2px);
}

/* hide email in feedback form */
.z-\[999999999\] > .p-3  > .space-y-2 > label {
  display: none;
}
.z-\[999999999\] > .p-3  > .space-y-2 > input[name="email"] {
  display: none;
}
.hidden {
  display: none;
}
.rounded-md {
  border-radius: calc(0.5rem - 2px);
}
.rounded-\[4px\] {
  border-radius: 4px;
}
.rounded-t-\[3px\] {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.ring-offset-background {
  --tw-ring-offset-color: hsl(0 0% 100%);
  border: 0;
}
.right-0 {
  right: 10px;
}
.bottom-0 {
  bottom: 10px;
}
.bottom-\[12px\] {
  bottom: 12px;
}
.bottom-\[65px\] {
  bottom: 65px;
}
.w-\[380px\] {
  width: 380px;
}
.mb-\[0\.85em\] {
  margin-bottom: 0.85em;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}
.shadow-\[0_6px_30px_2px_rgba\(34\2c 44\2c 79\2c 0\.3\)\] {
  --tw-shadow: 0 6px 30px 2px rgba(34,44,79,0.3);
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow-color: 0 6px 30px 2px;
  --tw-ring-offset-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-\[0_6px_16px_2px_rgba\(0\2c 0\2c 0\2c 0\.2\)\]:hover {
  --tw-shadow: 0 6px 16px 2px rgba(0,0,0,0.2);
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow-color: 0 6px 30px 2px;
  --tw-shadow-colored: 0 6px 16px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-primary-foreground {
  color: #FFF;
}
/* Hack to display feedback close dialog correctly*/
.opacity-70 {
  opacity: 0.7;
  -webkit-appearance: button;
  background-color: initial;
  background-image: none;
  border: 0;
}
.hover\:opacity-100:hover {
  opacity: 1;
}
.items-center {
  align-items: center;
}
.flex {
  display: flex;
}
.w-full {
  width: 100%;
}
.resize-none {
  resize: none;
}
.min-h-\[80px\] {
  min-height: 80px;
}
.mr-1\.5 {
  margin-right: 0.375rem;
}
.z-\[99999998\] {
  z-index: 99999998;
}
.z-\[999999999\] {
  z-index: 999999999;
}
.cursor-pointer {
  cursor: pointer;
}
.first-of-type\:-mr-\[1px\]:first-of-type {
  margin-right: -1px;
}
.first-of-type\:rounded-l-md:first-of-type {
  border-top-left-radius: calc(0.5rem - 2px);
  border-bottom-left-radius: calc(0.5rem - 2px);
}
.last-of-type\:-ml-\[1px\]:last-of-type {
  margin-left: -1px;
}
.last-of-type\:rounded-r-md:last-of-type {
  border-top-right-radius: calc(0.5rem - 2px);
  border-bottom-right-radius: calc(0.5rem - 2px);
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.bg-\[\#f4f4f7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 247 / var(--tw-bg-opacity));
}
.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

@media (max-width: 991px) {
  .ant-steps-item-title-content {
    max-width: calc( ( 100vw - ( 75px * 4 ) ) / 4 );
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
@media (min-width: 992px) {
  .ant-steps-item-title-content {
    max-width: calc( ( 100vw - 256px - ( 75px * 4 ) ) / 4 );
    text-overflow: ellipsis;
    overflow: hidden;
  }
}